import React from "react";
import { Container, Row, Col, Button, Card } from "reactstrap";
// import { FaShoppingCart } from "react-icons/fa";
import { Skeleton } from "@mui/material";

function Product({ data, externalLink }) {
  return (
    <section id="fullProductSection">
      <Row className="mx-auto">
        <Col sm={12} md={6} xl={5} className="px-0">
          <section className="productImage" id="productImageSection">
            <Container className="px-0">
              <Row className="mx-auto justify-content-center">
                <div>
                  {data?.organisation_icon && (
                    <img
                      src={data.organisation_icon.image}
                      alt={data.organisation_icon.alt ?? ""}
                      className="organisation-logo"
                    />
                  )}
                </div>
                <Col>
                  <div className="d-flex justify-content-center">
                    {data?.product_image?.image ? (
                      <img
                        src={data.product_image.image}
                        alt={data.product_image.alt ?? ""}
                        className="img-fluid"
                      />
                    ) : (
                      <Skeleton
                        variant="rounded"
                        height={400}
                        width="100%"
                        animation="wave"
                        className="mt-5"
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Col>
        <Col sm={12} md={6} xl={7} className="px-0">
          <section className="product" id="productSection">
            <Container className="px-0">
              <Row className="mx-auto justify-content-center">
                <Col>
                  <h2>
                    {data.title ? (
                      data.title
                    ) : (
                      <Skeleton variant="h2" animation="wave" />
                    )}
                  </h2>
                  <div className="d-flex justify-content-center">
                    {data.batch_no ? (
                      <Button
                        className="batch-button"
                        onClick={() => window.open(externalLink)}
                      >
                        Batch no.: {data.batch_no}
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/blockchain.png"
                          }
                          alt="blockchain"
                        />
                      </Button>
                    ) : (
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        width="60%"
                      />
                    )}
                  </div>
                  <p>
                    {data.description ? (
                      data.description
                    ) : (
                      <>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" width="80%" />
                      </>
                    )}
                  </p>
                  <div className="product-badges">
                    {data?.badges ? (
                      data?.badges?.map((badge) => (
                        <div className="card" key={badge.image}>
                          <img src={badge.image} alt={badge.alt ?? ""} />
                        </div>
                      ))
                    ) : (
                      <BadgesPlaceholder />
                    )}
                  </div>
                  {/* <div className="product-badges">
                    {data?.badges &&
                      data.badges.map((badge) => (
                        <div className="card">
                          <div
                            style={{
                              backgroundImage: `url(${badge.image})`,
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              width: "70px",
                              height: "70px",
                            }}
                          />
                        </div>
                      ))}
                  </div> */}
                  <div className="product-details">
                    {data?.title ? (
                      <Card>
                        <ul>
                          {data.brand && <li>Brand: {data.brand}</li>}
                          {data.origin && <li>Origin: {data.origin}</li>}
                          {data.weight && <li>Weight: {data.weight}</li>}
                          {data.dimensions && (
                            <li>Dimensions: {data.dimensions}</li>
                          )}
                          {data.expiration_date && (
                            <li>Expiration Date: {data.expiration_date}</li>
                          )}
                        </ul>
                      </Card>
                    ) : (
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        width="100%"
                        height={100}
                        sx={{ margin: "12px 0 22px 0" }}
                      />
                    )}
                  </div>
                  <Button
                    className={
                      data.title ? "purchase-btn" : "purchase-btn placeholder"
                    }
                    href={data.external_link}
                    target="_blank"
                  >
                    {/* <FaShoppingCart className={"cart-icon"} /> */}
                    Purchase in Store
                  </Button>
                  <div className="btn_group gap-2">
                    {data?.secondary_buttons &&
                      // eslint-disable-next-line
                      data.secondary_buttons.map((button) => (
                        <Button
                          key={button.label}
                          href={button.link}
                          target="_blank"
                        >
                          {button.label}
                        </Button>
                      ))}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Col>
      </Row>
    </section>
  );
}
export default Product;

const BadgesPlaceholder = () => (
  <div className="d-flex gap-2">
    {[...new Array(3).fill(0)].map((_, idx) => (
      <Skeleton
        key={idx}
        variant="rounded"
        width={80}
        height={80}
        animation="wave"
      />
    ))}
  </div>
);
