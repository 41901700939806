import React from "react";
import {
    Container,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

function Banner({ uniqueData }) {
    const msg = uniqueData.warning_message;
    const [warningmodal, setWarningModal] = React.useState(false);

    const warning_toggle = () => setWarningModal(!warningmodal);

    return (
        <Container className="mw-100 px-0">
            {msg && msg !== null && (
                <div className="warning">
                    <FontAwesomeIcon icon={faWarning} />
                    <p onClick={warning_toggle}>
                        {msg} (Item ID: {uniqueData.uid})
                    </p>
                </div>
            )}
            {/* Warning modal */}
            {uniqueData.warning_message &&
                uniqueData.warning_message !== null && (
                    <Modal
                        isOpen={warningmodal}
                        toggle={warning_toggle}
                        className="claim-modal"
                    >
                        <ModalHeader>Why is there a warning?</ModalHeader>
                        <ModalBody className="px-4">
                            <p>
                                In order to prevent fraudulent activities such
                                as counterfeits and tampering of the products,
                                our company uses AI-assisted fraud detection and
                                monitors performance and threats in real time to
                                provide a better customer experience and to
                                quickly respond to any issues that may arise. If
                                you are seeing warning alerts please reconsider
                                purchasing the products or respond accordingly.
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className="main-btn btn-lg"
                                onClick={warning_toggle}
                            >
                                Got It
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
        </Container>
    );
}

export default Banner;
