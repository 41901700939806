import React, { useState } from "react";
import {
    Box,
    Typography,
    Stack,
    InputLabel,
    TextField,
    Button,
    Checkbox,
} from "@mui/material";
import "../assets/feedback.styles.css";
import Swal from "sweetalert2";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const FeedbackForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [feedback, setFeedback] = useState("");
    const [checked, setChecked] = useState(false);

    const [searchParam] = useSearchParams();

    const contract = searchParam.get("contract");
    const id = searchParam.get("id");
    const title = searchParam.get("title");

    const handleCheckboxChange = () => {
        setChecked(!checked);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        axios
            .post(
                `https://verifier.thegrowhub.co/form/submit/dapp-feedback`,
                {
                    name: name,
                    email: email,
                    contact: contact,
                    feedback: feedback,
                    "contract-address": contract,
                    "batch-no": id,
                    "product-name": title.replace("AND99", "&"),
                },
                {
                    withCredentials: true,
                    mode: "no-cors",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .then((response) => {})
            .catch((error) => {
                // console.log("Error ========>", error);
                Swal.fire({
                    title: "Feedback submitted successfully!",
                    text: "Thank you.",
                    icon: "success",
                    confirmButtonText: "OK",
                    confirmButtonColor: "#4da045",
                }).then(() => {
                    window.close();
                });
            });
        setChecked(false);
    };

    return (
        <div className="feedback">
            <form
                className="w-100 d-flex justify-content-center"
                method="post"
                action="#"
                onSubmit={handleFormSubmit}
            >
                <Stack width={{ xs: "100%", sm: "32rem", md: "35rem" }}>
                    <Typography
                        sx={{
                            fontSize: { xs: "20px", sm: "28px" },
                            alignSelf: "center",
                            marginTop: { xs: "20px", sm: "40px" },
                        }}
                    >
                        Please give us your feedback
                    </Typography>
                    <Box className="feedback-form">
                        <div className="pt-2 pb-3">
                            <InputLabel htmlFor="name" required>
                                Name
                            </InputLabel>
                            <TextField
                                id="name"
                                name="name"
                                variant="filled"
                                size="small"
                                hiddenLabel
                                fullWidth
                                required
                                value={name}
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                            />
                        </div>
                        <div className="py-3">
                            <InputLabel htmlFor="email" required>
                                Email address
                            </InputLabel>
                            <TextField
                                id="email"
                                name="email"
                                variant="filled"
                                size="small"
                                type="email"
                                hiddenLabel
                                fullWidth
                                required
                                value={email}
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                            />
                        </div>
                        <div className="py-3">
                            <InputLabel htmlFor="contact">
                                Contact number (Optional)
                            </InputLabel>
                            <TextField
                                id="contact"
                                name="contact"
                                variant="filled"
                                size="small"
                                hiddenLabel
                                fullWidth
                                value={contact}
                                onChange={(event) =>
                                    setContact(event.target.value)
                                }
                            />
                        </div>
                        <div className="py-3">
                            <InputLabel htmlFor="feedback" required>
                                Feedback
                            </InputLabel>
                            <TextField
                                id="feedback"
                                name="feedback"
                                variant="filled"
                                size="small"
                                hiddenLabel
                                multiline
                                rows={5}
                                fullWidth
                                required
                                value={feedback}
                                onChange={(event) =>
                                    setFeedback(event.target.value)
                                }
                            />
                        </div>
                        <div className="d-flex">
                            <Checkbox
                                checked={checked}
                                onChange={handleCheckboxChange}
                            />
                            <Typography sx={{ alignSelf: "center" }}>
                                I consent to The GrowHub's{" "}
                                <a
                                    href="https://to.thegrowhub.co/out/www.growhub.sg/policies/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    privacy policy
                                </a>
                                .
                            </Typography>
                        </div>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{
                                marginTop: "20px",
                                width: { xs: "100%", sm: "9rem" },
                                alignSelf: "flex-end",
                                backgroundColor: "#4BA04B",
                                ":hover": {
                                    bgcolor: "#2b8522",
                                    color: "white",
                                },
                                ":focus": {
                                    bgcolor: "#2b8522",
                                    color: "white",
                                },
                            }}
                            disabled={checked ? false : true}
                        >
                            Submit
                        </Button>
                    </Box>
                </Stack>
            </form>
        </div>
    );
};

export default FeedbackForm;
