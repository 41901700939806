import React, { useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleInfo,
    faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const ItemVerified = ({ uniqueData }) => {
    const [modal, setModal] = useState(false);
    const [claimedTooltipModal, setClaimedTooltipModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
    };

    const toggleClaimedTooltipModal = () => {
        setClaimedTooltipModal(!claimedTooltipModal);
    };

    return (
        <Container className="item-verified mw-100">
            <Row>
                <Col>
                    <div className="d-flex justify-content-center align-items-center mt-2">
                        <img
                            src={
                                process.env.PUBLIC_URL + "/images/thumb_up.png"
                            }
                            alt="thumbUp-icon"
                        />
                        <span>Item Verified!</span>
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            className="dir-icon"
                            onClick={toggleModal}
                        />
                    </div>
                </Col>
            </Row>
            <Modal
                isOpen={modal}
                toggle={toggleModal}
                className="verified-modal"
            >
                <div className="header-wrapper">
                    <img
                        src={process.env.PUBLIC_URL + "/images/logo.png"}
                        alt="logo"
                    />
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                        <span>Report issue</span>
                    </div>
                </div>
                <ModalHeader className="pb-0 pt-4">
                    Transparent information you can Trust
                </ModalHeader>
                <ModalBody className="px-4">
                    <p>
                        By tracing the detailed journey of products, you can be
                        assured the safety and quality of the product, along
                        with blockchain technology to provide real-time
                        authenticity and safety information such as reports and
                        certificates that you can trust.
                    </p>

                    {uniqueData?.claimable === false && (
                        <>
                            <div className="claimed">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/images/purchased-badge.png"
                                    }
                                    alt="Claim Logo"
                                    className="claimed-img"
                                />
                                <div>
                                    {uniqueData.owner_name !== "" && (
                                        <span className="text-start">
                                            By: {uniqueData.owner_name}
                                        </span>
                                    )}
                                    {uniqueData.claimed_date !== "" && (
                                        <span className="text-start">
                                            Date:{" "}
                                            {format(
                                                new Date(
                                                    uniqueData.claimed_date
                                                ),
                                                "dd/MM/yyyy"
                                            )}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-3 claimed-tooltip">
                                <span onClick={toggleClaimedTooltipModal}>
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        className="claimed-icon"
                                    />
                                    What's this for?
                                </span>
                            </div>
                        </>
                    )}
                </ModalBody>
                <ModalFooter className="px-3">
                    <Button
                        className="main-btn btn-md verified-button"
                        onClick={toggleModal}
                    >
                        OK
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={claimedTooltipModal}
                toggle={toggleClaimedTooltipModal}
                className="claim-modal"
            >
                <ModalHeader className="pb-0">Authenticity Check</ModalHeader>
                <ModalBody className="px-4">
                    <p className="text-center">
                        If you are trying to check the authenticity of this
                        product and you are seeing this, this product is
                        possibly fake.
                    </p>
                    <p className="text-center">
                        Contact your distributor and send us your feedback
                        below.
                    </p>
                </ModalBody>
                <ModalFooter className="justify-content-between">
                    <Link to="/feedback">
                        <Button className="main-btn btn-md">Feedback</Button>
                    </Link>
                    <Button
                        className="main-btn btn-md"
                        onClick={toggleClaimedTooltipModal}
                    >
                        Got it
                    </Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
};

export default ItemVerified;
