import React from "react";
import LocationSelect from "./LocationSelect";
import useQueryState from "./useQueryState";
// import { FiExternalLink } from "react-icons/fi";
import { Col, Container, Row, Button } from "reactstrap";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  // TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { Typography, Skeleton } from "@mui/material";

const Trace = (props) => {
  const { chain, data, externalLink } = props;
  const [selected, setSelected] = useQueryState("location");

  // modify the data shape from chain to fit the splitting UI
  const transform = (chain) => {
    const seen = new Map();
    const transformedData = [];
    chain.forEach((item) => {
      if (!seen.get(item.senderDesignation)) {
        transformedData.push({
          ...item,
          opposite: [],
        });
        seen.set(item.senderDesignation, true);
      } else {
        let foundItem = transformedData.find(
          (data) => data.senderDesignation === item.senderDesignation
        );
        foundItem.opposite = [...foundItem.opposite, item];
      }
    });

    return transformedData;
  };

  // transformed chain data
  const transformedChain = transform(chain);

  let selectedTimeline = transformedChain;

  if (selected) {
    let row = selectedTimeline.findIndex(
      (item) =>
        item.designation + "_" + item.transferQty === selected ||
        item?.opposite?.find(
          (i) => i.designation + "_" + i.transferQty === selected
        )
    );

    const foundItem = selectedTimeline[row]?.opposite?.find(
      (i) => i.designation + "_" + i.transferQty === selected
    );

    if (foundItem) {
      let temp = selectedTimeline[row];
      selectedTimeline[row] = {
        ...foundItem,
        opposite: [
          ...temp.opposite.filter((i) => i.designation !== selected),
          {
            date: temp.date,
            designation: temp.designation,
            senderDesignation: temp.senderDesignation,
            time: temp.time,
            transferQty: temp.transferQty,
          },
        ],
      };
    }
    selectedTimeline = transformedChain.slice(row, transformedChain.length);
  }

  return (
    <section className="trace" id="traceSection">
      <Container className="px-0">
        <Row>
          <Col sm="12" md="5">
            <h1>
              <img
                src={process.env.PUBLIC_URL + "/images/footprints.png"}
                alt="footprints"
                className="footprints-img"
              />
              Product Footprints
            </h1>
            <h3>{data.name}</h3>
            <div className="d-flex justify-content-center">
              <Button
                className="batch-button"
                onClick={() => window.open(externalLink)}
              >
                Batch no.: {data.batch_no}
                <img
                  src={process.env.PUBLIC_URL + "/images/blockchain.png"}
                  alt="blockchain"
                />
              </Button>
            </div>
          </Col>

          <Col sm="12" md="7" className="mx-auto">
            <div className="my-4 mx-1">
              <h3
                className="text-center d-block"
                style={{
                  fontSize: "20px",
                  marginBottom: "15px",
                }}
              >
                View Your Product Journey
              </h3>
              <LocationSelect
                chain={chain}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
            <div>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: { xs: 1, sm: 3, md: 4 },
                  },
                }}
              >
                {!(chain.length > 0) && <TimelinePlaceholder />}
                {selectedTimeline.map((item, index) => (
                  <TimelineItem key={item.designation + item.date + item.time}>
                    {/* <TimelineOppositeContent>
                                        {item.opposite.length > 0 &&
                                            item.opposite.map((i) => (
                                                <Typography key={i.designation}>
                                                    <b>{i.designation}</b>
                                                </Typography>
                                            ))}
                                    </TimelineOppositeContent> */}
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          marginBottom: 0,
                          bgcolor: `${index === 0 ? "#4da045" : "#d4d4d4"}`,
                        }}
                      />
                      <TimelineConnector sx={{ bgcolor: "#d4d4d4" }} />
                    </TimelineSeparator>
                    <TimelineContent
                      color={index === 0 ? "#4da045" : "#b5b5b5"}
                    >
                      <Typography>
                        {item.date} {item.time}
                      </Typography>

                      <Typography color={index === 0 ? "#aecdab" : "#d4d4d4"}>
                        [Units received: {item.transferQty}]
                      </Typography>
                      <Typography>
                        <b>Arrived at {item.designation}</b>
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Trace;

const TimelinePlaceholder = () => (
  <>
    {[...new Array(4).fill(0)].map((_, idx) => (
      <TimelineItem key={idx}>
        <TimelineSeparator>
          <TimelineDot sx={{ marginBottom: 0 }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Skeleton
            variant="text"
            width="50%"
            sx={{ fontSize: "1.3rem" }}
            animation="wave"
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.3rem" }}
            animation="wave"
          />
        </TimelineContent>
      </TimelineItem>
    ))}
  </>
);
