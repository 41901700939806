const registryABI = [
    {
        inputs: [
            {
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "getDesignation",
        outputs: [
            {
                components: [
                    {
                        internalType: "string",
                        name: "name",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "description",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "location",
                        type: "string",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "lat",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "long",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct InformationRegistry.Coordinates",
                        name: "latlong",
                        type: "tuple",
                    },
                    {
                        internalType: "string",
                        name: "country",
                        type: "string",
                    },
                    {
                        internalType: "string",
                        name: "uri",
                        type: "string",
                    },
                ],
                internalType: "struct InformationRegistry.Entry",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "hasDesignation",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
// product: [
//     {
//         anonymous: false,
//         inputs: [
//             {
//                 indexed: true,
//                 internalType: "address",
//                 name: "from",
//                 type: "address",
//             },
//             {
//                 indexed: true,
//                 internalType: "address",
//                 name: "to",
//                 type: "address",
//             },
//             {
//                 indexed: true,
//                 internalType: "uint256",
//                 name: "tokenId",
//                 type: "uint256",
//             },
//         ],
//         name: "Transfer",
//         type: "event",
//     },
//     {
//         inputs: [
//             {
//                 internalType: "uint256",
//                 name: "tokenId",
//                 type: "uint256",
//             },
//         ],
//         name: "tokenURI",
//         outputs: [
//             {
//                 internalType: "string",
//                 name: "",
//                 type: "string",
//             },
//         ],
//         stateMutability: "view",
//         type: "function",
//     },
//     {
//         inputs: [
//             {
//                 internalType: "uint256",
//                 name: "tokenId",
//                 type: "uint256",
//             },
//         ],
//         name: "getReports",
//         outputs: [
//             {
//                 components: [
//                     {
//                         internalType: "uint256",
//                         name: "timestamp",
//                         type: "uint256",
//                     },
//                     {
//                         internalType: "string",
//                         name: "title",
//                         type: "string",
//                     },
//                     {
//                         internalType: "string",
//                         name: "contents",
//                         type: "string",
//                     },
//                 ],
//                 internalType: "struct ProductBatch.Report[]",
//                 name: "",
//                 type: "tuple[]",
//             },
//         ],
//         stateMutability: "view",
//         type: "function",
//     },
//     {
//         inputs: [
//             {
//                 internalType: "uint256",
//                 name: "tokenId",
//                 type: "uint256",
//             },
//         ],
//         name: "getLogs",
//         outputs: [
//             {
//                 components: [
//                     {
//                         internalType: "uint256",
//                         name: "timestamp",
//                         type: "uint256",
//                     },
//                     {
//                         internalType: "string",
//                         name: "contents",
//                         type: "string",
//                     },
//                 ],
//                 internalType: "struct ProductBatch.Log[]",
//                 name: "",
//                 type: "tuple[]",
//             },
//         ],
//         stateMutability: "view",
//         type: "function",
//     },
// ],

export default registryABI;
