import React from "react";
import { Navbar, NavItem, NavLink, Nav, Button } from "reactstrap";
// import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import "../assets/feedback.styles.css";

function Header({ feedbackParams }) {
    const handleClickProduct = () => {
        const element = document.getElementById("productSection");
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    const handleClickProductInfo = () => {
        const element = document.getElementById("infoSection");
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    const handleClickTrace = () => {
        const element = document.getElementById("traceSection");
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    const handleClickMore = () => {
        const element = document.getElementById("moreSection");
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <Navbar light expand="md" fixed="top">
            {/* <NavbarBrand href="https://thegrowhub.co/" target="_blank">
                <img
                    src={process.env.PUBLIC_URL + "/images/logo.png"}
                    alt="Logo"
                    className="img-fluid logo"
                />
            </NavbarBrand> */}
            {/* <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} /> */}
            {/* <Collapse isOpen={isOpen} navbar> */}
            <Nav className="mr-auto" navbar>
                <NavItem>
                    <NavLink onClick={handleClickProduct}>Product</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={handleClickProductInfo}>
                        Product Info
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={handleClickTrace}>Footprints</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={handleClickMore}>More Info</NavLink>
                </NavItem>
            </Nav>

            {/* <Nav className="right-nav">
                <NavItem>
                    <NavLink
                        href="https://www.facebook.com/thegrowhub.sg/"
                        target="_blank"
                        className="facebook"
                    >
                        <FaFacebookSquare />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        href="https://www.instagram.com/thegrowhub.sg/"
                        target="_blank"
                    >
                        <FaInstagram />
                    </NavLink>
                </NavItem>
                */}
            <NavItem className="feedback-nav">
                <NavLink
                    href={`/#/feedback/?contract=${feedbackParams.contract}&id=${feedbackParams.id}&title=${feedbackParams.title}`}
                    target="_blank"
                >
                    <Button
                        className="feedback-btn"
                        disabled={feedbackParams.title ? false : true}
                    >
                        Feedback
                    </Button>
                </NavLink>
            </NavItem>
            {/* </Nav>  */}
        </Navbar>
    );
}

export default Header;
