import React from "react";
import { Container, Row } from "reactstrap";

const Footer = () => {
    return (
        <div className="footer" id="footerSection">
            <Container>
                <Row className="d-flex justify-content-center">
                    <span>Traceability proudly brought to you by</span>
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/images/thegrowhub_logo_small.png"
                        }
                        alt="logo"
                        className="footer-logo"
                    />
                    <div className="d-flex justify-content-center gap-2 my-2">
                        <a
                            href="https://thegrowhub.co/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL + "/images/link.png"
                                }
                                alt="link"
                                className="link-logo"
                            />
                        </a>
                        <a
                            href="https://sg.linkedin.com/company/thegrowhub"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="facebook"
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/images/linkedin.png"
                                }
                                alt="linkedin"
                                className="linkedin-logo"
                            />
                        </a>
                    </div>
                    <p>
                        Copyright 2023 THE GROWHUB All Rights Reserved |{" "}
                        <a
                            href="https://thegrowhub.co/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>{" "}
                        |{" "}
                        <a
                            href="https://thegrowhub.co/terms-of-service"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms of Service
                        </a>
                    </p>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;
