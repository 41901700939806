import React, { useState } from "react";
import classnames from "classnames";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Container,
} from "reactstrap";
import { Box, Skeleton, Stack } from "@mui/material";
import { FiExternalLink, FiZoomIn } from "react-icons/fi";

function More({ data, envData }) {
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState("3");

  // Toggle active state for Tab
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  return (
    <section className="more text-center" id="moreSection">
      <h1>
        <img
          src={process.env.PUBLIC_URL + "/images/assurance.png"}
          alt="assurance"
          className="assurance-img"
        />
        Product Assurance
      </h1>
      <Container>
        <Row className="main-row">
          <Col sm="12" md="10" className="mx-auto detail-box mt-0">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === "1",
                  })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/report.png"}
                    alt="Reports"
                    className="img-fluid normal-icon"
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/images/report-hv.png"}
                    alt="Reports"
                    className="img-fluid active-icon"
                  />{" "}
                  Reports
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === "2",
                  })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/env-data.png"}
                    alt="Environment"
                    className="img-fluid normal-icon"
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/images/env-data-hv.png"}
                    alt="Environment"
                    className="img-fluid active-icon"
                  />{" "}
                  Environmental Data
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === "3",
                  })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/certificate.png"}
                    alt="Certificate"
                    className="img-fluid normal-icon"
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/images/certificate-hv.png"}
                    alt="Certificate"
                    className="img-fluid active-icon"
                  />{" "}
                  Certificates
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={currentActiveTab}>
              <TabPane tabId="1">
                <Row>
                  <Col>
                    <div className="assurance-tab">
                      <p style={{ padding: "40px 20px" }}>
                        More details coming soon!
                      </p>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col>
                    <div className="assurance-tab">
                      {envData.length > 0 ? (
                        <ul className="environment">
                          {envData.map((item, index) => (
                            <li key={index}>
                              <div>
                                <p>
                                  {item.date}, {item.time}
                                </p>
                                <p>
                                  {item.data.key}
                                  <span>{item.data.value}</span>
                                </p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p
                          style={{
                            padding: "40px 20px",
                          }}
                        >
                          More details coming soon!
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row className="certificate-section">
                  <Col>
                    <div className="assurance-tab">
                      {data.certificates ? (
                        data.certificates?.length > 0 ? (
                          data.certificates.map((item, index) => {
                            return (
                              <div className="certificate">
                                <div className="d-flex align-items-center w-100">
                                  <div style={{ width: "70px" }}>
                                    <img
                                      src={item.image}
                                      alt="Certificate"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <span>{item.name}</span>
                                </div>
                                <div className="certificate-action-btn">
                                  <a
                                    href={item.image}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    View Image
                                    <FiZoomIn className="zoom-in-icon" />
                                  </a>
                                  <a
                                    href={item.detail_link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    View Certificate
                                    <FiExternalLink className="external-link-icon" />
                                  </a>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p
                            style={{
                              padding: "40px 20px",
                            }}
                          >
                            More details coming soon!
                          </p>
                        )
                      ) : (
                        <div className="w-100 d-flex justify-content-center">
                          <Stack
                            width={{
                              xs: "100%",
                              sm: "70%",
                              md: "50%",
                            }}
                          >
                            <CertPlaceholder />
                            <CertPlaceholder />
                            <CertPlaceholder />
                          </Stack>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default More;

// Placeholder components
const CertPlaceholder = () => {
  return (
    <Box className="certificate-border-placeholder">
      <Box sx={{ margin: 1 }}>
        <Skeleton variant="circular" animation="wave" height={50} width={50} />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Skeleton width="100%" variant="text" animation="wave" />
        <Skeleton width="70%" variant="text" animation="wave" />
      </Box>
    </Box>
  );
};
