import Select from "react-select";

const LocationSelect = ({ chain, selected, setSelected }) => {
  const hasBalance = chain.filter((i) => i.balanceUnits > 0);

  const options = hasBalance.map((i) => ({
    label: i.designation,
    value: i.designation + "_" + i.transferQty,
  }));

  const disableSelect = options.length < 0;

  return (
    <>
      {options?.length > 1 && (
        <Select
          onChange={(option) => {
            setSelected(option?.value);
          }}
          options={options}
          value={options.find((i) => i.value === selected)}
          placeholder="Select a location to start..."
          disableSelect={disableSelect}
          noOptionsMessage={"No locations"}
        />
      )}
    </>
  );
};

export default LocationSelect;
