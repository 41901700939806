import React, { useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Skeleton } from "@mui/material";
import { FiExternalLink } from "react-icons/fi";

// const ingredientBtnColors = ["#437c3d", "#3b8a63", "#1a7749", "#53a07a"];

function Info({ data }) {
  const [activeImgIndex, setActiveImgIndex] = useState(0);

  const youtube_link = data?.product_video?.youtube_link;
  const youtubeID = youtube_link && youtube_link.split("v=")[1].split("&")[0];

  return (
    <section className="info" id="infoSection">
      <Container className="px-0">
        <Row className="mx-auto justify-content-center">
          <Col md={6} sm={12}>
            <div className="d-block">
              <h1>
                <img
                  src={process.env.PUBLIC_URL + "/images/info.png"}
                  alt="info"
                  className="info-img"
                />
                Product Information
              </h1>
            </div>
          </Col>
          <Col md={6} sm={12}>
            {data?.product_video?.youtube_link && (
              <iframe
                title="Youtube player"
                src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}
                className="youtube-video"
              />
            )}

            <div className="gallery-active-img">
              {data?.gallery ? (
                <img
                  src={data.gallery[activeImgIndex].image}
                  alt={data.gallery[activeImgIndex].alt ?? ""}
                />
              ) : (
                <ActiveImgSkeleton />
              )}
            </div>
            <div className="gallery-thumbnail">
              {data?.gallery ? (
                data.gallery.map((item, idx) => (
                  <img
                    key={item.image}
                    id={idx}
                    src={item.image}
                    alt={item.alt ?? ""}
                    width={75}
                    height={75}
                    onClick={(e) => setActiveImgIndex(e.target.id)}
                  />
                ))
              ) : (
                <ThumbnailSkeleton />
              )}
            </div>
            <div className="ingredients">
              <p>
                <img
                  src={process.env.PUBLIC_URL + "/images/plant.png"}
                  alt="ingredients"
                  className="ingredients-img"
                />
                Ingredients
              </p>
              {data?.ingredients?.length > 0 ? (
                data.ingredients.map((ingredient) =>
                  ingredient.link ? (
                    <Button
                      key={ingredient.name}
                      className="w-100 mb-1 ingredients-button"
                      style={{
                        backgroundColor: "#437c3d",
                      }}
                      onClick={() =>
                        ingredient.link &&
                        window.open(ingredient.link, "_blank")
                      }
                    >
                      {ingredient.name}
                      <FiExternalLink className="external-link-icon ms-2 mb-1" />
                    </Button>
                  ) : (
                    <Button
                      key={ingredient.name}
                      className="w-100 mb-1 ingredients-button"
                      style={{
                        cursor: "default",
                        backgroundColor: "#1a7749",
                      }}
                    >
                      {ingredient.name}
                    </Button>
                  )
                )
              ) : (
                <>
                  <IngredientsSkeleton />
                  <IngredientsSkeleton />
                  <IngredientsSkeleton />
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Info;

const IngredientsSkeleton = () => (
  <Skeleton
    variant="rounded"
    animation="wave"
    sx={{
      bgcolor: "grey.700",
      marginBottom: 1,
    }}
  />
);

// const VideoSkeleton = () => (
//     <Skeleton
//         variant="rounded"
//         animation="wave"
//         height={220}
//         sx={{ bgcolor: "grey.700",marginBottom: '20px' }}
//     />
// );

const ActiveImgSkeleton = () => (
  <Skeleton
    variant="rounded"
    animation="wave"
    height={250}
    sx={{ bgcolor: "grey.700" }}
  />
);

const ThumbnailSkeleton = () => (
  <>
    {[...new Array(4).fill(0)].map((_, idx) => (
      <Skeleton
        key={idx}
        variant="rounded"
        // width="100%"
        width={80}
        height={80}
        animation="wave"
        sx={{ bgcolor: "grey.700" }}
      />
    ))}
  </>
);
